<template>
  <slice-block v-if="data.slices" :slices="data.slices" />
</template>

<script setup>
import { getContextualisedPageData } from '~/utils/helpers/data';
import getHead from '~/utils/get-head';
const context = useNuxtApp();
const requestEvent = useRequestEvent();
const { data } = await useAsyncData('data', async () => {
  const { error } = context;
  try {
    const data = await getContextualisedPageData(
      context,
      'homepage',
      requestEvent
    );

    if (typeof data === 'undefined') {
      error({
        statusCode: 404,
      });
    }

    return data;
  } catch (e) {
    console.log('Error getting slice data', e);
  }
});

useHead(getHead(context, [], null, { hasOrganization: true }));
</script>
